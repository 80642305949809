import React, { Suspense, useCallback, useLayoutEffect } from 'react'
import { lazy } from 'react'

import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'

import '@interco/inter-ui/inter-ui.css'
import HomeTabId from '@/pages/Home/HomeTabId'
import { AreaClienteActiveMenu } from '@/pages/Home/AreaCliente/AreaClienteActiveMenu'
import LoadingPage from '@/components/templates/LoadingPage'
import { useAppDispatch } from '@/store/hooks'
import BaseService from '@/services/BaseService'
import { setInfos } from '@/store/infosSlice'
import { enableDarkMode } from '@interco/inter-ui'

import routes from './types'
import PrivateRoute from './PrivateRouter'

const Home = lazy(() => import('../pages/Home'))
const Autorizacao = lazy(() => import('../pages/Autorizacao'))
const ContaSalario = lazy(() => import('../pages/Avisos/ContaSalario'))
const Saldo = lazy(() => import('../pages/NaoAutorizado/Saldo'))
const MobileBackButton = lazy(() => import('../utils/MobileBackButton'))
const Resultado = lazy(() => import('../pages/NaoAutorizado/Resultado'))
const Error = lazy(() => import('../pages/Error'))
const Espera = lazy(() => import('../pages/Espera'))
const Proposta = lazy(() => import('@interco/cp-area-correntista-fgts-ui-proposta'))
const ResultadoAutorizado = lazy(() => import('../pages/Autorizado/Resultado'))
const RevisaoAutorizado = lazy(() => import('../pages/Autorizado/Revisao'))
const CCB = lazy(() => import('../components/templates/Contrato/CCB'))
const ResumoContrato = lazy(() => import('@interco/cp-area-correntista-fgts-ui-contrato'))
const Confirmacao = lazy(() => import('../pages/Autorizado/Confirmacao'))
const SaibaMais = lazy(() => import('../pages/SaibaMais'))
const Onbording = lazy(() => import('../pages/Onbording'))
const PrivateRouterNaoAutorizado = lazy(() => import('./PrivateRouterNaoAutorizado'))
const ContratacaoAutorizado = lazy(() => import('../pages/Autorizado/Contratacao'))
const LimiteFlexivel = lazy(() => import('../pages/LimiteFlexivel'))

const MoveToTop = () => {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <div />
}

const propostaProps = {
  env: process.env.REACT_APP_ENV,
  callbackUrl: `${routes.HOME}?tab=${HomeTabId.AREA_CLIENTE}&menu=${AreaClienteActiveMenu.PROPOSTAS}`,
}

const resumoProps = {
  env: process.env.REACT_APP_ENV,
  callbackUrl: `${routes.HOME}?tab=${HomeTabId.AREA_CLIENTE}&menu=${AreaClienteActiveMenu.CONTRATOS}`,
  callbackAntecipacaoUrl: `${routes.HOME}?contratarNovaAntecipacao=true`,
}

const Router = () => {
  const dispatch = useAppDispatch()

  const getInfos = useCallback(async () => {
    if (await BaseService.isWeb()) return
    const infos = await BaseService.getAppInfo()
    dispatch(setInfos(infos))
    if (infos?.isDarkMode) enableDarkMode()
  }, [dispatch])

  React.useEffect(() => {
    getInfos()
  }, [getInfos])

  return (
    <Suspense fallback={<LoadingPage />}>
      <BrowserRouter basename="/">
        <MobileBackButton />
        <MoveToTop />
        <Routes>
          <Route path={routes.HOME} element={<Home />} />
          <Route path={routes.ANTECIPACAO_HOME_CREDITO} element={<Home />} />
          <Route path={routes.AUTORIZACAO} element={<Autorizacao />} />
          <Route path={routes.CONTA_SALARIO} element={<ContaSalario />} />
          <Route path={routes.NAO_AUTORIZADO_SALDO} element={<Saldo />} />
          <Route
            path={routes.NAO_AUTORIZADO_RESULTADO}
            element={
              <PrivateRouterNaoAutorizado>
                <Resultado />
              </PrivateRouterNaoAutorizado>
            }
          />
          <Route path={routes.ERROR} element={<Error />} />
          <Route path={routes.ESPERA} element={<Espera />} />
          <Route path={routes.PROPOSTA} element={<Proposta {...propostaProps} />} />
          <Route
            path={routes.AUTORIZADO_RESULTADO}
            element={
              <PrivateRoute>
                <ResultadoAutorizado />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.AUTORIZADO_REVISAO}
            element={
              <PrivateRoute>
                <RevisaoAutorizado />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.AUTORIZADO_CONTRATACAO}
            element={
              <PrivateRoute>
                <ContratacaoAutorizado />
              </PrivateRoute>
            }
          />
          <Route path={routes.CONTRATO_CCB} element={<CCB />} />
          <Route path={routes.RESUMO_CONTRATO} element={<ResumoContrato {...resumoProps} />} />
          <Route path={routes.AUTORIZADO_CONFIRMACAO} element={<Confirmacao />} />
          <Route path={routes.SAIBA_MAIS} element={<SaibaMais />} />
          <Route path={routes.ONBOARDING} element={<Onbording />} />
          <Route path={routes.LIMITE_FLEXIVEL} element={<LimiteFlexivel />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default Router

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import routes from '@/routes/types'
import validarErro from '@/utils/validarErro'
import PropostaService from '@/services/PropostaService'
import { getMensagemErro } from '@/utils'
import { ParametroProposta } from '@/models/api/proposta/ParametroProposta'
import PropostaResponse from '@/models/api/proposta/PropostaResponse'
import BaseService from '@/services/BaseService'

type PropostaState = {
  loading: boolean
  error: boolean
  data?: PropostaResponse[]
}

const initialState: PropostaState = {
  loading: false,
  error: false,
}

export const getPropostasThunk = createAsyncThunk(
  'propostas/getPropostas',
  async (payload: ParametroProposta, { rejectWithValue }) => {
    try {
      const user = await BaseService.getUserInfo()
      const { data } = await PropostaService.getPropostas(user?.cpf, payload.page, payload.size)
      return data
    } catch (e) {
      if (payload.error) {
        const messageErro = getMensagemErro(e as ErrorResponseApi)
        payload?.error(routes.ERROR, validarErro(messageErro as string))
      }
      return rejectWithValue(e)
    }
  },
)

const propostaSlice = createSlice({
  name: 'propostas',
  initialState,
  reducers: {
    setPropostas: (state, action: PayloadAction<PropostaResponse[]>) => {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPropostasThunk.fulfilled, (state, action) => {
        state.error = false
        state.loading = false
        state.data = action.payload as PropostaResponse[]
      })
      .addCase(getPropostasThunk.pending, (state) => {
        state.loading = true
      })
      .addCase(getPropostasThunk.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  },
})

export const [propostaReducer, propostaActions] = [propostaSlice.reducer, propostaSlice.actions]

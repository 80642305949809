import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import routes from '@/routes/types'
import validarErro from '@/utils/validarErro'
import { getMensagemErro } from '@/utils'
import { ParametroContrato } from '@/models/api/contrato/ParametroContrato'
import ContratoService from '@/services/ContratoService'
import ContratoResponse from '@/models/api/backoffice/ContratoResponse'
import BaseService from '@/services/BaseService'

type ContratoState = {
  loading: boolean
  error: boolean
  data?: ContratoResponse
}

const initialState: ContratoState = {
  loading: false,
  error: false,
}

export const getContratoThunk = createAsyncThunk(
  'contratos/getContratos',
  async (payload: ParametroContrato, { rejectWithValue }) => {
    try {
      const user = await BaseService.getUserInfo()
      const { data } = await ContratoService.getContratoFgts(user?.cpf)
      return data
    } catch (e) {
      if (payload.error) {
        const messageErro = getMensagemErro(e as ErrorResponseApi)
        payload?.error(routes.ERROR, validarErro(messageErro as string))
      }
      return rejectWithValue(e)
    }
  },
)

const contratoSlice = createSlice({
  name: 'propostas',
  initialState,
  reducers: {
    setPropostas: (state, action: PayloadAction<ContratoResponse>) => {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContratoThunk.fulfilled, (state, action) => {
        state.error = false
        state.loading = false
        state.data = action.payload as ContratoResponse
      })
      .addCase(getContratoThunk.pending, (state) => {
        state.loading = true
      })
      .addCase(getContratoThunk.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  },
})

export default contratoSlice.reducer

export const { setPropostas } = contratoSlice.actions

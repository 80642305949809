import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import SeguroService from '@/services/SegurosService'

export interface GetResponseSeguros {
  eligibility: boolean
  widget_tag: WidgetTag
  widget_info: WidgetInfo
  bottomsheet: Bottomsheet
}

export interface WidgetTag {
  text: string
  text_color: string
  background_color: string
  style: boolean
}

export interface WidgetInfo {
  widget_title: string
  widget_subtitle: string
  widget_description: string
  widget_checked: boolean
  widget_details_button: string
}
export interface Bottomsheet {
  title: string
  advantages: Advantage[]
  details: Details
  manualUrl: string
  termsUrl: string
}

export interface Advantage {
  icon: string
  title: string
  description: string
  coverage_title: string
  coverage_value: string
}

export interface Details {
  title: string
  monthly_amount_text: string
  monthly_amount_value: string
  payment_method_text: string
  payment_method_value: string
}

export interface SeguroState {
  loading: boolean
  isSucess: boolean
  error: boolean
  checked: boolean
  data?: GetResponseSeguros
}

const initialState: SeguroState = {
  loading: false,
  error: false,
  isSucess: false,
  checked: false,
}

export const getWidgetSeguros = createAsyncThunk(
  'widget/seguros',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await SeguroService.getDadosSeguro()
      return data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

const seguroSlice = createSlice({
  name: 'seguros',
  initialState,
  reducers: {
    setChecked: (state, action: PayloadAction<boolean>) => {
      state.checked = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWidgetSeguros.fulfilled, (state, action) => {
        state.error = false
        state.loading = false
        state.isSucess = true
        state.data = action.payload
      })
      .addCase(getWidgetSeguros.pending, (state) => {
        state.loading = true
        state.error = false
        state.isSucess = false
      })
      .addCase(getWidgetSeguros.rejected, (state) => {
        state.loading = false
        state.error = true
        state.isSucess = false
      })
  },
})

export const { setChecked } = seguroSlice.actions

export default seguroSlice.reducer
